import React from "react"
import Scroller from "./scroller"
import { StaticImage } from "gatsby-plugin-image"

const ArticleHero = () => {
  return (
    <header className="masthead">
      <div className="container py-4">
        <div className="row align-items-top justify-content-center text-center">
          <div className="col-lg-10 align-self-end">
            <StaticImage
              src="../images/nozzolillo-logo-trans.png"
              alt="Anthony A. Nozzolillo, Esq. logo"
              placeholder="blurred"
              width={200}
              height={200}
            />
            <br />
            <br />

            <h1 className="text-uppercase text-black font-weight-bold">
              In the News
            </h1>
          </div>

          <div className="card card-width m-2">
            <div className="card-body">
              <StaticImage
                className="card-img-top"
                src="https://gamma.creativecirclecdn.com/liherald/original/20240110-133250-RECENT%20LEGAL%20PIC.jpg"
                alt="Anthony A. Nozzolillo, Esq. logo"
                placeholder="blurred"
                height="240"
              />
              <h5 className="card-title">
                Meet Anthony A. Nozzolillo, Esq., Long Island Real Estate
                Attorney whose expertise goes well beyond closings
              </h5>
              <p className="card-text">Long Island Herald</p>
              <a
                href="https://www.liherald.com/stories/meet-anthony-a-nozzolillo-esq-long-island-real-estate-attorney-whose-expertise-goes-well-beyond,205334"
                className="btn btn-secondary"
              >
                Read Article
              </a>
            </div>
          </div>

          <div className="card card-width m-2">
            <div className="card-body">
              <StaticImage
                className="card-img-top"
                src="https://gamma.creativecirclecdn.com/liherald/original/20240110-133250-RECENT%20LEGAL%20PIC.jpg"
                alt="Anthony A. Nozzolillo, Esq. logo"
                placeholder="blurred"
                height="240"
              />
              <h5 className="card-title">
                Meet Anthony A. Nozzolillo, Esq., Long Island Real Estate
                Attorney whose expertise goes well beyond closings
              </h5>
              <p className="card-text">Long Island Herald</p>
              <a
                href="https://www.liherald.com/stories/meet-anthony-a-nozzolillo-esq-long-island-real-estate-attorney-whose-expertise-goes-well-beyond,205334"
                className="btn btn-secondary"
              >
                Read Article
              </a>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default ArticleHero
